.dark {
  --card-text-color: var(--blue);
  --card-bg-color: var(--beige);
}

.light {
  --card-text-color: var(--white);
  --card-bg-color: var(--black);
}

.container {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid var(--grey);
  border-radius: var(--radius-s);
  transition: border 0.3s ease-in-out;
  height: 100%;
}

.horizontal.container {
  flex-flow: row wrap;
  width: 100%;
}

.container:hover {
  border: 1px solid var(--blue);
}

.image {
  position: relative;
  height: 300px;
}

.horizontal .image {
  height: 100%;
  min-height: 211px;
  width: 50%;
}

.info {
  display: flex;
  flex-flow: column wrap;
  color: var(--card-bg-color);
  padding: var(--spacing-40);
  gap: var(--spacing-24);
  flex: 1;
}
.dark .info {
  color: var(--card-text-color);
  background: var(--card-bg-color);
}
.horizontal .info {
  padding: var(--spacing-16);
  justify-content: space-between;
}

.content {
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
  justify-content: flex-start;
}
.city {
  font: var(--font-p1-desktop-default);
  font-weight: 800;
}

.address {
  font: var(--font-p2-desktop-default);
}

.price {
  font-weight: 800;
}

.tag {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-8);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-8);
  align-items: flex-end;
}

.tag > * {
  width: fit-content;
}

.horizontal .tag {
  top: var(--spacing-8);
  left: var(--spacing-8);
  align-items: flex-start;
}

@media (max-width: 1024px) {
  .city {
    font: var(--font-h3-mobile-default);
    font-weight: 800;
  }
  .address {
    font: var(--font-p2-mobile-default);
  }
  .image {
    height: 240px;
  }

  .content {
    padding: 0;
  }
}
