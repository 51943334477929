.light{
  --text-color: var(--blue);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --bg-color: var(--black);
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-64);
  background: var(--bg-color);
  color: var(--text-color);
  padding: var(--padding-container);
}

.title{
  padding: 0 !important;
}

.link{
  display: flex;
  align-self: flex-end;
  padding: var(--spacing-40);
}

@media (max-width: 1200px) {
  .container{
    padding: var(--padding-container) 0;
  }

  .carousel, .title{
    padding: 0 var(--spacing-80) !important;
  }
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-24) 0;
    padding: var(--padding-container);
    gap: var(--spacing-24);
  }

  .carousel, .title{
    padding: 0 var(--spacing-24) !important;
  }
}
